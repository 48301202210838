<template>
  <div class="space-y-4">
    <asom-modal
      title="Create Ticket Log (Pre-encoded Ticket)"
      v-model="showModal"
      :dismissible="false"
    >
      <p>
        This will create a ticket log of {{ formData.issueTickets }} tickets for
        the event {{ formData.event.label }}
      </p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <div class="pb-5 col-span-2">
          <legend class="text-base font-medium text-gray-900">
            Location
          </legend>
          <p class="text-sm text-gray-500">
            PSC
          </p>
        </div>
        <asom-form-field
          label="Event Name"
          required
          :state="inputStates('formData.event')"
          description="Type to search"
          error="Event required"
        >
          <asom-input-select
            :state="inputStates('formData.event')"
            v-model="formData.event"
            :options="eventOptions"
          />
        </asom-form-field>
        <asom-form-field
          label="Issue Tickets"
          required
          :state="inputStates('formData.issueTickets')"
          :error="maxTicketValueString"
        >
          <asom-input-text
            type="number"
            v-model="formData.issueTickets"
            :state="inputStates('formData.issueTickets')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push({ name: 'Pre Encoded Ticket Main Page' })"
          />
          <asom-button
            text="Submit"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import filter from "lodash.filter";
import { mapGetters } from "vuex";
import find from "lodash.find";
import { required, between, numeric } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  getListOfTicketEvents,
  createTicketLog,
} from "../../../../services/cashManagement.service";

export default {
  name: "CreateTicket",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      events: [],
      formData: {
        event: null,
        issueTickets: null,
      },
      isLoading: false,
      eventOptions: [],
      isLoadingEventOptions: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  mounted() {
    this.getEvents();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getEvents();
    },
  },
  validations() {
    return {
      formData: {
        event: { required },
        issueTickets: {
          required,
          numeric,
          between: between(1, this.getMaxTicketValue()),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      userStationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    maxTicketValueString() {
      let maxValue = this.getMaxTicketValue();
      return "Issue Tickets required, minimum value of 1 and maximum Value of tickets is " + maxValue;
    },
  },
  methods: {
    async getEvents() {
      this.isLoadingEventOptions = true;
      const result = await getListOfTicketEvents({
        stationId: this.userStationId,
        lineId: this.lineId,
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.events = get(result, "payload.list", []);
        this.isLoadingEventOptions = false;
        this.error = null;
        this.eventOptions = filter(get(result, "payload.list", []), {
          isClosed: false,
        }).map(({ eventId, eventName }) => ({
          label: eventName,
          value: eventId,
        }));
      } else {
        this.isLoadingEventOptions = false;
        this.error = result.payload;
        this.$scrollTop();
        this.eventOptions = [];
      }
    },
    getMaxTicketValue() {
      if (this.formData.event) {
        let selectedEvent = find(this.events, {
          eventId: this.formData.event.value,
        });
        return get(selectedEvent, "noOfTickets", 0);
      } else return 0;
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createTicketLog({
        pscId: this.pscId,
        eventId: get(this.formData.event, "value"),
        noOfTickets: this.formData.issueTickets,
        transactionType: 2,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "Pre Encoded Ticket Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
